import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class TipoUsuario {
  user = [];
  constructor(private http: HttpClient) {
  }

    getTipoUsuario() {
      return this.http.get(config.apiUrl+'/TipoUsuario');
    }

    

}
