import {Injectable} from '@angular/core';

@Injectable()
export class AuthService {

  constructor(
    private storage: Storage = localStorage
  ) { }

  get isAuthenticated(): boolean {
    try {
      const userJson = this.storage.getItem('user')
      const user: {token: string} = JSON.parse(userJson)
      return user.token.length > 0
    } catch {
      return false
    }
  }

}
