import { Component, OnInit } from '@angular/core';
import { TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GrupoService } from 'src/app/data-access/grupo.service';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { TipoUsuario } from 'src/app/data-access/tipoUsuario.service'

class EM
{
  IdEncuesta: string ="";
  IdTipoUsuario: number;
  GrupoNombre: string;
  Cuenta: string;
  //Clave: string;
  CodigoValidacion: string;
  Nombres: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;
  DestinoVisita: string;
  MotivoVisita: string;
  AutorizaAcceso: string;
  Respuestas: string;
  Resultado: boolean;
  Placas:string;
  Observaciones:string;
  Plantel: number;
}

@Component({
  selector: 'app-guardia',
  templateUrl: './guardia.component.html',
  styles: [
  ]
})
export class GuardiaComponent implements OnInit {
  flogin: FormGroup | any;
  mlogin: FormGroup | any;
  usuarioTipo;
  usuarioSitio;
  constructor(
    private router: Router,
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private grupoService: GrupoService,
    private tipoUsuario: TipoUsuario,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) { 
    //this.usuarioTipo = localStorage.getItem('usuarioTipo');
    //this.usuarioTipo = 104;
    this.usuarioTipo = localStorage.getItem('usuarioTipo');
    this.usuarioSitio = localStorage.getItem('usuarioSitio');
    this.getTipoUsuario();
    this.setSitio(this.usuarioSitio);
    this.iniciarForm();
    
  }
  @ViewChild('anuncio') anuncio: TemplateRef<any>;
  ngOnInit(): void {
    localStorage.setItem('csh', '');
    //localStorage.setItem('user', '');
  }

  //@ViewChild('anuncio') anuncio: TemplateRef<any>;
  ngAfterViewInit(){
    
    //console.log(this.usuarioTipo);
    //alert(1);
    if(this.usuarioTipo!=103){
      //Solo si es visitante
      this.modal.open(this.anuncio, { size: 'xl' });
    }    

    
  }  

  iniciarForm() {
    this.flogin = this.fb.group({
      cuenta: [''],
      clave: [''],
      token: [''],
      tipo: [0],
      digito: [''],
      recordar: [false],
      nombres: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      apellidoMaterno: ['', Validators.required],
      destinoVisita: ['', Validators.required],
      motivoVisita: ['', Validators.required],
      autorizaAcceso: ['', Validators.required],
      placas: [''],
      observaciones: ['']
      //,recaptcha: new FormControl('', Validators.required),
    });
    this.mlogin = this.flogin;
  }  

  Usuario;
  UsuariosLista;
  usuarioText;
  UsuarioIcono;
  getTipoUsuario()
  {
    this.spinner.show();
    this.tipoUsuario.getTipoUsuario().toPromise()
      .then(data => {
        this.UsuariosLista = data;
        this.Usuario = this.UsuariosLista.find(x => Number(x.idTipoUsuario) === Number(this.usuarioTipo));
        this.usuarioText = this.Usuario.descripcion;
        this.UsuarioIcono = this.Usuario.icono;
        //console.log(this.Usuario);
        this.spinner.hide();
      })
  }
  
  logo;
  bgapp;
  setSitio(sitio){
    //this.sitio = 1;
    this.bgapp = '';
    if(sitio==1) { //1 - CDMX
      this.logo='ibero-090.svg';
      this.bgapp = 'bg-ibero-rojo';
    }
    if(sitio==2){ //2 - PREPA
      this.logo='logo-prepa.svg';
      this.bgapp = 'bg-ibero-rojo';
    }
    if(sitio==3){ //3 - TIJUANA
      this.logo='ibero-099.svg';
      this.bgapp = '';
    }
  }   
  
  usrVisita;
  sUsuario;
  entrar() {
    //console.log(this.mlogin);
    this.sUsuario = this.usuarioService.getUser();
    this.usrVisita = new EM();
    this.usrVisita.Cuenta = "0";
    
    if(this.sUsuario.cuenta!=undefined){
      //console.log(this.sUsuario);
      this.usrVisita.Cuenta = this.sUsuario.cuenta; //Cuenta de guardia
    }
    
    this.usrVisita.IdTipoUsuario = this.usuarioTipo;
    this.usrVisita.GrupoNombre = this.usuarioText;
    this.usrVisita.CodigoValidacion = "";
    this.usrVisita.Nombres = this.mlogin.nombres;
    this.usrVisita.ApellidoPaterno = this.mlogin.apellidoPaterno;
    this.usrVisita.ApellidoMaterno = this.mlogin.apellidoMaterno;
    this.usrVisita.DestinoVisita = this.mlogin.destinoVisita;
    this.usrVisita.MotivoVisita = this.mlogin.motivoVisita;
    this.usrVisita.AutorizaAcceso = this.mlogin.autorizaAcceso;
    //Las placas y las observaciones son opcionales. si están vacías, les asignamos un valor en blanco.
    //let vPlacas = "";
    this.usrVisita.Placas = this.mlogin.placas;
    if(this.mlogin.placas==undefined){ 
      this.usrVisita.Placas = "";
    }
    
    this.usrVisita.Observaciones = this.mlogin.observaciones;
    if(this.mlogin.observaciones==undefined){ 
      this.usrVisita.Observaciones = "";
    }

    this.usrVisita.Plantel = this.usuarioService.getSitio();

    //console.log(this.usrVisita);
    this.usuarioService.addVisita(this.usrVisita);
    this.router.navigate(['/e-encuesta']);
  }

  regresar(){
    localStorage.setItem('noredirect', 'true');
    this.router.navigate(['/perfil']);           
  }  

}
