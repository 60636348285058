import { Component, OnInit } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { EncuestaService } from 'src/app/data-access/encuesta.service';

class EM
{
  IdEncuesta: string ="";
  IdTipoUsuario: number;
  GrupoNombre: string;
  Cuenta: string;
  //Clave: string;
  CodigoValidacion: string;
  Nombres: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;
  DestinoVisita: string;
  MotivoVisita: string;
  AutorizaAcceso: string;    
  Respuestas: string;
  Resultado: boolean;
  Placas:string;
  Observaciones:string;
  Plantel: number;
}

@Component({
  selector: 'app-e-consulta',
  templateUrl: './e-consulta.component.html',
  styles: [
  ]
})
export class EConsultaComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = 'https://ibero.mx';

  display = true;
  href : string;
  qrColor: string;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public us: UsuarioService,
    private encuestaService: EncuestaService,
    private activatedRoute: ActivatedRoute
    ) { }

  idEncuesta;
  /*
  res_texto;
  res_qrcode;
  */
  resultado;
  qresultado;
  vigencia;
  qvigencia;
  acceso;
  qacceso;
  
 
  //sUsuario;
  nombreCompleto;
  grupo;
  cuenta;
  fechaRegistro;
  misma;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.idEncuesta = params['id'];
      this.getEncuesta(this.idEncuesta);
    });
  }

  gAutorizaAcceso;
  autorizaAcceso;
  getEncuesta(idEncuesta){
    this.spinner.show();
    this.encuestaService.getEncuestaById(idEncuesta).subscribe(
      (res) => {  
        //console.log(res);
        this.misma=true;
        this.gAutorizaAcceso = res["autorizaAcceso"];
        this.autorizaAcceso = this.gAutorizaAcceso;
        this.resultado = 'No Seguro';
        this.qresultado = 'text-danger';       
        if(Boolean(JSON.parse(res["resultado"]))){
          this.resultado = 'Seguro';
          this.qresultado = 'text-success';
        }
        
        this.vigencia = 'Expirado';
        this.qvigencia = 'text-danger';        
        if(Boolean(JSON.parse(res["acceso"]))){
          this.vigencia = "Vigente";
          this.qvigencia = 'text-success';
        }

        this.acceso = "No Permitido";
        this.qacceso = 'text-danger';        
        if(Boolean(JSON.parse(res["resultado"])) && Boolean(JSON.parse(res["acceso"]))){
          this.acceso = "Permitido";
          this.qacceso = 'text-success';
        }
        else{
          //Si el acceso es NO PERMITIDO, mostrar la vigencia en rojo
          this.qvigencia = 'text-danger';          
        }

        this.fechaRegistro = this.formatDate(res["fechaRegistro"]);
        this.vigencia = this.formatDate(res["fechaVigencia"]);
        this.nombreCompleto = res["nombres"] + ' ' + res["apellidoPaterno"] + ' ' + res["apellidoMaterno"];
        this.grupo = res["grupoNombre"];
        this.cuenta = res["cuenta"];
        
        this.spinner.hide(); 
      },
      (error) => {
        console.log(error);
        this.spinner.hide(); 
      });    
  }

  addEncuesta;
  sUsuario;
  registrar(){
    this.spinner.show();
    this.addEncuesta = new EM();
    //this.addEncuesta.idEncuesta = this.idEncuesta
    this.sUsuario = this.us.getUser();
    this.addEncuesta.IdEncuesta = this.idEncuesta;
    this.addEncuesta.Cuenta = this.sUsuario.cuenta;
    this.addEncuesta.AutorizaAcceso = this.autorizaAcceso;
    //console.log(this.addEncuesta);
    //return false;
    //Visitante
    this.encuestaService.registroIngreso(this.addEncuesta).subscribe(
      (res) => {  
        this.encuestaService.addEDatos(res);
        //this.router.navigate(['/e-resultado/']);
        this.spinner.hide();
        this.toastr.success('El ingreso ha sido registrado');
        this.router.navigate(['/guardia']);
      },
      (error) => {
        console.log(error);
        if(error.message=="Unauthorized")
        {
          this.router.navigate(['/login']);
        }
      });

  }
  
  inicio() {
    this.router.navigate(['/guardia']);
  }
  
  personaVisita(values:any){
    let state = values.currentTarget.checked
    this.misma = state;
    //alert(values.currentTarget.checked);
    //console.log(this.misma);
    if(state){
      //let res = this.encuestaService.getEDatos();
      this.autorizaAcceso = this.gAutorizaAcceso;
    }
    else{
      this.autorizaAcceso = "";
    }
  }  
 
  formatDate(fecha) {
    var d = new Date(fecha);
    d.setHours(0, 0, 0, 0);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  }
}
