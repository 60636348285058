import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { GrupoService } from 'src/app/data-access/grupo.service';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { TipoUsuario } from 'src/app/data-access/tipoUsuario.service'

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styles: [
  ]
})
export class PerfilComponent implements OnInit {

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private grupoService: GrupoService,
    private tipoUsuario: TipoUsuario,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    localStorage.setItem('valido', '0');

    //this.getSitio(this.sitio);
    //this.sitio = this.usuarioService.getSitio();
    this.getSitio(undefined);
    let noredirect = localStorage.getItem('noredirect');
    let cov19rec = localStorage.getItem('cov19rec');

    if (noredirect != 'true') {
      if (cov19rec == 'true') {
        this.router.navigate(['/login']);
      }
      else {
        localStorage.setItem('user', '');
      }
    }

  }

  selectedUser = 2;
  isSelected = false;
  onSelect(index): void {
    this.selectedUser = index;
  }

  //UsuariosLista;
  //GrupoLista;
  ngOnInit(): void {
    this.selectedUser = 2;
    //Validamos si hay algún grupo seleccionado
    let user = localStorage.getItem('usuarioGrupo');
    //console.log(user);
    if (user != undefined) {
      this.selectedUser = Number(user);
    }
    this.getGrupoLista();
    //this.getTipoUsuario();
    console.log(user);

    this.activatedRoute.queryParams.subscribe(params => localStorage.setItem("redirectURL", params["redirectURL"]));
  }

  GrupoLista;
  getGrupoLista() {
    this.spinner.show();
    this.grupoService.getGrupo().toPromise()
      .then(data => {
        this.GrupoLista = data;
        this.spinner.hide();
        //console.log(this.GrupoLista);
        this.getTipoUsuario();
      })
  }

  UsuariosLista;
  Usuarios;
  getTipoUsuario() {
    this.spinner.show();
    this.tipoUsuario.getTipoUsuario().toPromise()
      .then(data => {
        this.Usuarios = data;
        //console.log(this.Usuarios)
        this.UsuariosLista = this.Usuarios.filter(x => x.planteles.includes(this.sitio));
        const unique = [...new Set(this.UsuariosLista.map(item => item.idGrupo))];
        //console.log(unique);
        this.GrupoLista = this.GrupoLista.filter(i => unique.includes(i.idGrupo));

        if (this.sitio == 3) {
          this.GrupoLista.push({
            estatus: true,
            icono: "book-open",
            idGrupo: 5,
            nombre: "Manual"
          })
        }

        console.log(this.GrupoLista)
        this.spinner.hide();
      })
  }

  UsuariosFiltrados;
  idGrupo;
  grupoDesc;
  @ViewChild('manual') manual: TemplateRef<any>;
  filtra(content, idGrupo) {

    //Si es el manual, solo abrimos una nueva ventana
    if (idGrupo == 5) {
      //alert("manual");
      this.modal.open(this.manual, { size: 'xl' });
      return false;
    }

    this.idGrupo = idGrupo;
    this.grupoDesc = this.GrupoLista.find(x => Number(x.idGrupo) === Number(this.idGrupo)).descripcion;
    this.UsuariosFiltrados = this.UsuariosLista.filter(x => Number(x.idGrupo) === Number(this.idGrupo));

    //Si es personal o alumnado, ponemos estos grupos al inicio de los elementos
    if (idGrupo == 1 || idGrupo == 2) {
      let first = 0;
      let data = this.UsuariosFiltrados;
      if (idGrupo == 1) first = 24;
      if (idGrupo == 2) {
        first = 21;
        if (this.sitio == 2) first = 34;
      }


      data.unshift(data.splice(data.findIndex(item => item.idTipoUsuario === first), 1)[0])
      this.UsuariosFiltrados = data;
    }
    this.rows();
    this.modal.open(content, { size: 'xl' });
  }

  actRows;
  rows() {
    this.actRows = [];
    this.UsuariosFiltrados.forEach((item, index) => {
      if (index % 5 == 0) {
        let row = [];
        row.push(item);
        this.actRows.push(row);
      } else {
        this.actRows[this.actRows.length - 1].push(item);
      }
    });
  }

  //filtra(id){}
  //onSelect(id){}



  login(tipo) {
    //alert(tipo);
    this.modal.dismissAll();
    localStorage.setItem('usuarioGrupo', this.selectedUser.toString());
    localStorage.setItem('usuarioTipo', tipo);
    localStorage.setItem('usuarioSitio', this.sitio);
    localStorage.setItem('noredirect', '');
    if (tipo == 104 || tipo == 105 || tipo == 110) {
      this.router.navigate(['/guardia']);
    }
    else {
      this.router.navigate(['/login']);
    }

  }

  sitio;
  logo;
  bgapp;
  bgtext;
  getSitio(sitio) {
    //console.log();
    this.sitio = sitio;
    if (sitio == undefined) {
      this.sitio = this.usuarioService.getSitio();
    }

    this.bgapp = '';
    if (this.sitio == 1) { //1 - CDMX
      this.logo = 'ibero-090.svg';
      this.bgapp = 'bg-ibero-rojo';
      this.bgtext = '';
    }
    if (this.sitio == 2) { //2 - PREPA
      this.logo = 'logo-prepa.svg';
      this.bgapp = 'bg-ibero-rojo';
      this.bgtext = '';
    }
    if (this.sitio == 3) { //3 - TIJUANA
      this.logo = 'ibero-099.svg';
      this.bgapp = '';
      this.bgtext = 'text-ibero';
    }
  }

}
