<!--Cierre de sesión-->
<div (window:beforeunload)="onBeforeUnload()"></div>
<!--Contenedor-->
<div class="container align-items-center flex-column" #screen>

    <!--Encabezado-->
    <div class="text-center pt-3">
      <h4 class="title-section">Resultado</h4>
      
    </div>
  
    <!--Listado-->
          <table class="table table-striped text-center table-fixed">
            <thead class="bg-secondary text-white text-left">
              <tr>
                <th class="w-10 text-center">
                  &nbsp;
                </th>  
              </tr>
            </thead>
            <tbody>
              <tr class="rStyle t-show">
                <td>
                  <div class="row text-dark">
                    <div class="col-lg-4 col-md-12 text-center">
                      <label class="elinea">
                        <p>
                            <ngx-qrcode
                            id="QRCode"
                            [elementType]="elementType"
                            [errorCorrectionLevel]="correctionLevel"
                            [value]="value"
                            [colorDark]="qrColor"
                            cssClass="bshadow"></ngx-qrcode>                        
                        </p>
                      </label>
                    </div>
                    <div class="col-lg-8 col-md-12 text-center f-big">
                      <p [ngClass]="qacceso" class="font-weight-bold"><label>Acceso </label> {{acceso}}</p>
                      <p [ngClass]="qvigencia" class="font-weight-bold"><label>Vigencia: </label> {{vigencia}}</p>
                      <!--p [ngClass]="qresultado"><label class="font-weight-bold">Resultado: </label> {{resultado}}</p-->
                      <p class="text-dark font-weight-bold"><label>Nombre y cuenta: </label> {{cuenta}} {{nombreCompleto}}</p>
                      <!--p><label class="text-dark font-weight-bold">Grupo: </label> {{grupo}}</p-->
                      <!--p><label class="text-dark font-weight-bold">Cuenta / Folio: </label> {{cuenta}}</p-->
                      <p class="text-dark font-weight-bold"><label>Fecha registro: </label> {{fechaRegistro}}</p>
    
                    </div>
                  </div>
                </td>           
              </tr>
              <tr>
                <td>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button *ngIf="display" type="button" class="btn btn-danger mr-4" (click)="downloadImage();">
                      Descargar Imagen
                    </button>
                    <button type="button" *ngIf="us.anony()" class="btn btn-danger " (click)="logout();">Salir</button>
                  </p>
                    <div *ngIf="us.grd()" class="row">
                      <div class="col-2 py-2 text-right">
                      <label for="autorizaAcceso">Autoriza el acceso: </label>
                      </div>
                      <div class="col-4 text-left">
                        <input class="form-control" id="autorizaAcceso" type="text" placeholder="Persona que autoriza el acceso"
                        [(ngModel)]="autorizaAcceso" [readonly]="misma?true:false" />  
                      </div>                      
                      <div class="col-3 text text-left">
                        <label for="misma">
                          <input type="checkbox" id="misma" 
                          [checked]="misma?'checked':''" 
                          (change)="personaVisita($event);" />                  
                          La misma persona a quien visita
                        </label> 
                      </div>
                      <div class="col-3 text text-left">
                        <button type="button" class="btn btn-danger mr-2" (click)="registrar();">Registrar Ingreso</button>
                        <button type="button" *ngIf="us.grd()" class="btn btn-danger" (click)="inicio();">Inicio</button>
                      </div>
                    </div>             
                  <p>
                    {{ res_texto }}
                  </p>
                  <p>
                    {{ res_qrcode }}
                  </p>                
                </td>  
              </tr>              
            </tbody>
            <tfoot class="tfoot-ibero text-center bg-secondary text-white">
                <th class="w-10 text-left ">
                    &nbsp;
                </th>  
            </tfoot>
          </table>
        </div>