<div class="bg-login">
<div class="container">
  <div class="row align-items-center minh-100">
    <div class="col-md-12">
      <div class="card shadow-lg border-0 rounded-lg mt-2" id="box-login">
        <div class="card-header text-center text-white" [ngClass]="bgapp" >
          <a class="navbar-brand" [routerLink]="['/perfil/']">
            <img src="../../../../assets/img/login/{{logo}}" class="img-login" id="img-login">
          </a>
        </div>
        <div class="card-body">
          <form [formGroup]="flogin" >
            <div class="form-group text-center" >
              <input id="idTipoUsuario" type="number" formControlName="idTipoUsuario" [(ngModel)]="mlogin.idTipoUsuario" class="hide" />              
              <button class="btn icon-btn mb-4" ngbTooltip="">
                <la-icon *ngIf="UsuarioIcono" [icon]="UsuarioIcono" size="2x" class="img-box align-middle p-3 item-image bg-white rounded text-danger"></la-icon>
              </button>
              <p class="text-danger item-text" >{{usuarioText}}</p>
            </div>
            <div id="divCuenta" [ngClass]="classCuenta">
              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">
                <label class="mb-1" for="cuenta">{{uCuenta}}</label>
                </div>
                <div [ngClass]="(usuarioTipo==4 || usuarioTipo==21 || usuarioTipo==107)?'col-lg-7 col-md-6':'col-lg-11 col-md-11'">
                  <input class="form-control py-2" id="cuenta" type="text" placeholder="{{uCuenta + uCuentaNota}}"
                  formControlName="cuenta" [(ngModel)]="mlogin.cuenta" />
                </div>
                <div *ngIf="(usuarioTipo==4 || usuarioTipo==21 || usuarioTipo==107)" class="col-lg-4 col-md-5">
                  <input class="form-control py-2" id="digito" type="text" placeholder="Digito"
                  formControlName="digito" [(ngModel)]="mlogin.digito" 
                  maxlength="1" (keypress)="alphanumericOnly($event)"
                  [readonly]="false" />
                </div>
              </div>
              <div [ngClass]="classClave" class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">
                  <label class="mb-1" for="clave">{{uClave}}</label>
                </div>
                <div class="col-lg-11 ">
                    <input class="form-control py-2" id="clave" type="password" placeholder="{{uClavePH}}"
                    formControlName="clave" [(ngModel)]="mlogin.clave" />
                </div>              
              </div>
            </div>

            <div id="divNombre" [ngClass]="className">
              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">
                  <label class="mb-1" for="nombres">Nombre</label>
                </div>
                <div class="col-lg-11 col-md-11">
                    <input class="form-control py-2" id="nombres" type="text" placeholder="Nombres"
                    formControlName="nombres" [(ngModel)]="mlogin.nombres" />
                </div>                  
              </div>
              
              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">&nbsp;</div>
                <div class="col-11">
                    <input class="form-control py-2" id="apellidoPaterno" type="text" placeholder="Apellido Paterno"
                    formControlName="apellidoPaterno" [(ngModel)]="mlogin.apellidoPaterno" />
                </div>              
              </div>
              
              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">&nbsp;</div>
                <div class="col-11">
                    <input class="form-control py-2" id="apellidoMaterno" type="text" placeholder="Apellido Materno"
                    formControlName="apellidoMaterno" [(ngModel)]="mlogin.apellidoMaterno" />
                </div>              
              </div>               

            </div>
            <div id="divVista" [ngClass]="classVisita">
            <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
              <div class="col-1">
                <label class="mb-1" for="destinoVisita">Lugar</label>
              </div>
              <div class="col-11">
                  <input class="form-control py-2" id="destinoVisita" type="text" placeholder="¿A dónde se dirige?"
                  formControlName="destinoVisita" [(ngModel)]="mlogin.destinoVisita" />
              </div>              
            </div>

            <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
              <div class="col-1">
                <label class="mb-1" for="motivoVisita">Motivo</label>
              </div>
              <div class="col-11">
                  <input class="form-control py-2" id="motivoVisita" type="text" placeholder="Motivo de la visita"
                  formControlName="motivoVisita" [(ngModel)]="mlogin.motivoVisita" />
              </div>              
            </div>
            
            <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
              <div class="col-1">
                <label class="mb-1" for="autorizaAcceso">Autoriza</label>
              </div>
              <div class="col-11">
                  <input class="form-control py-2" id="autorizaAcceso" type="text" placeholder="Persona que autoriza el acceso"
                  formControlName="autorizaAcceso" [(ngModel)]="mlogin.autorizaAcceso" />
              </div>              
            </div>             
          </div>

            <div class="form-group d-flex align-items-center text-left mt-2">
              <label for="recordar" class="text-primary">
                <input type="checkbox" id="recordar" 
                formControlName="recordar" [(ngModel)]="mlogin.recordar" />                  
                Recordar mis credenciales
              </label>
            </div>
            <div class="form-group d-flex align-items-center text-left mt-2">
              <label for="recordar">
                <input type="checkbox" id="recordar" 
                formControlName="recordar" [(ngModel)]="ap" />                  
                <a href="#" (click)="showap(aviso); false">  He leído y acepto el aviso de privacidad</a>
              </label>
              <!--a href="https://ibero.mx/aviso-legal-y-de-privacidad" 
              target="_blank" class="text-ibero">Ver Aviso de Privacidad</a-->
            </div>            

            <div class="form-group d-flex align-items-center justify-content-center mt-4 mb-0">
            <button class="btn btn-danger text-white mr-2" (click)="regresar()" >Regresar</button>
            <button class="btn btn-danger text-white" (click)="entrar()" [disabled]="!ap">Entrar</button>
            </div>



          </form>
        </div>
        <div class="small mb-1 text-center">
          <div class="small"><label class="text-white">Versión 2.1.0</label></div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<!-- Anuncio  -->
<ng-template #anuncio let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-ibero-rojo">&nbsp;</div>
      <div class="card-body text-dark p-0 text-center">
        <img class="anuncio_login" src="../../../../assets/img/login/anuncio003.svg" />
      </div>
    </div>
  </div>
</ng-template>

<!-- Aviso  -->
<ng-template #aviso let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" src="../assets/IberoAvisoDePrivacidad2022.pdf" allowfullscreen></iframe>
    </div>
    <!--div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-ibero-rojo">&nbsp;</div>
      <div class="card-body text-dark p-0 text-center">
        <img class="anuncio_login" src="../assets/IberoAvisoDePrivacidad2022.pdf" />
      </div>
    </div-->
  </div>
</ng-template>