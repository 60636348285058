<div class="bg-login">
    <div class="container">
      <div class="row align-items-center minh-100">
        <div class="col-md-12">
          <div class="card shadow-lg border-0 rounded-lg mt-2" id="box-login">
            <div class="card-header text-center text-white" [ngClass]="bgapp" >
              <a class="navbar-brand" [routerLink]="">
                <img src="../../../../assets/img/login/{{logo}}" class="img-login" id="img-login">
              </a>
            </div>
            <div class="card-body">
    
            <div class="row align-items-center mt-2 bg-white text-ibero">           
                <div class="col-md-6 text-center mt-3" *ngFor="let item of GrupoLista">
                    <button [class.selected]="selectedUser==item.idGrupo" class="btn icon-btn pt-2 pb-3 pl-1 pr-1" 
                    (click)="filtra(content, item.idGrupo);onSelect(item.idGrupo)" ngbTooltip="">
                        <!--img class="img-box p-3 item-image bg-white rounded animate__animated animate__backInDown" src="/assets/img/usr/{{ item.tipoId }}.svg" alt="" /-->
                        <la-icon [icon]="item.icono" size="2x" class="img-box p-3 item-image bg-white rounded text-ibero"></la-icon>
                    </button>
                    <p class="item-text text-ibero" >{{ item.nombre }}</p>
                </div>
            </div>
    
            </div>
            <div class="small mb-1 text-center">
              <div class="small"><label class="text-white">Versión 2.1.0</label></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
    <!-- Usuario  -->
    <ng-template #content let-modal>
        <div class="modal-hidden">
          <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    
        <div class="modal-body">
          <div class="card border-dark mb-0">
            <div class="card-header text-white text-center bg-ibero-rojo">{{grupoDesc}}</div>
            <div class="card-body text-dark">
    
                <div class="row" *ngFor="let rowData of actRows">
                    <div class="col-md-1 text-center">&nbsp;</div>
                    <div class="col-md-2 text-center" *ngFor="let item of rowData">
                        <button class="btn icon-btn py-4" (click)="login(item.idTipoUsuario);" ngbTooltip="">
                            <la-icon [icon]="item.icono" size="2x" class="img-box align-middle p-3 item-image bg-white rounded text-ibero"></la-icon>
                        </button>
                        <p class="text-danger item-text" >{{ item.descripcion }}</p>
                    </div>
                    <div class="col-md-1 text-center">&nbsp;</div>
                </div>
    
            </div>
          </div>
        </div>
    </ng-template>

    <!-- Manual  -->
    <ng-template #manual let-modal>
      <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <div class="modal-body">
        <div class="card border-dark mb-0">
          <div class="card-header text-white text-center bg-ibero-rojo">&nbsp;</div>
          <div class="card-body text-dark p-0 text-center">
            <embed src="../../../../assets/pdf/Manual Cuestionario de Salud v.1 IberoTijuana.pdf" frameborder="0" width="100%" height="400px">
          </div>
        </div>
      </div>
  </ng-template>   