<div *ngIf="ls.validar(); then thenTemplateName else elseTemplateName">
  Here is never showing
</div>

<ng-template #thenTemplateName>

  <div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">
      <!--app-nav-menu></app-nav-menu-->
      <div class="container-fluid" id="contenido">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #elseTemplateName>
  <router-outlet></router-outlet>
  <!--app-login></app-login-->
  <!--app-perfil></app-perfil-->
</ng-template>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> cargando... </p>
</ngx-spinner>
