import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/data-access/login.service';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import * as $ from 'jquery';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent {
  isExpanded = false;
  validar = false;

  constructor(
    public ls: LoginService,
    public us: UsuarioService,
    private router: Router
  ) {
    this.getSitio();
  }

  collapse() {
    this.isExpanded = true;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  ngOnInit() {
    //Toggle Click Function
    
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

  }

  logout() {
    localStorage.setItem('valido', '0');
    localStorage.setItem('user', '');
    this.router.navigate(['/login']);
  }

  sitio;
  logo;
  bgapp;
  getSitio(){
    this.sitio = this.us.getSitio();
    this.bgapp = '';
    if(this.sitio==1) { //1 - CDMX
      this.logo='ibero-090.svg';
      this.bgapp = 'bg-ibero-rojo';
    }
    if(this.sitio==2){ //2 - PREPA
      this.logo='logo-prepa.svg';
      this.bgapp = 'bg-ibero-rojo';
    }
    if(this.sitio==3){ //3 - TIJUANA
      this.logo='ibero-099.svg';
      this.bgapp = '';
    }
  }  

  Usuario='';
  home() {
    this.router.navigate(['/']);
  }

  inicio() {
    this.router.navigate(['/guardia']);
  }  

}
