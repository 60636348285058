import { UiFullNameHeaderService } from './../../../data-access/ui-full-name-header.service';
import { Component, OnInit } from '@angular/core';
import { TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { GrupoService } from 'src/app/data-access/grupo.service';
import { TipoUsuario } from 'src/app/data-access/tipoUsuario.service'
import * as myGlobals from 'src/app/data-access/util';
import { NgRecaptcha3Service } from 'ng-recaptcha3'
import { HttpClient } from '@angular/common/http';
import { config } from 'src/app/data-access/config';
import { EncuestaService } from 'src/app/data-access/encuesta.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  flogin: FormGroup | any;
  mlogin;
  /*enabled = false;
  loading = false;*/
  usuarioGrupo;
  usuarioTipo;
  usuarioSitio;
  //UsuariosLista;
  Usuario;
  UsuarioIcono;
  cuentasExterno;
  constructor(
    private router: Router,
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private grupoService: GrupoService,
    private tipoUsuario: TipoUsuario,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private recaptcha3: NgRecaptcha3Service,
    private http: HttpClient,
    public modal: NgbModal,
    private encuestaService: EncuestaService,
    private uiFullNameService: UiFullNameHeaderService
  ) {
    this.cuentasExterno = '8,10,32,33,106';
    localStorage.setItem('valido', '0');
    this.usuarioGrupo = localStorage.getItem('usuarioGrupo');
    this.usuarioTipo = localStorage.getItem('usuarioTipo');
    this.usuarioSitio = localStorage.getItem('usuarioSitio');
    //alert(this.usuarioTipo);
    this.setLabels();
    this.getTipoUsuario();


    //this.setTipoUsuario(this.usuarioTipo);
    this.setSitio(this.usuarioSitio)
    this.iniciarForm();
  }

  UsuariosLista;
  usuarioImg;
  usuarioText;
  getTipoUsuario() {
    this.spinner.show();
    this.tipoUsuario.getTipoUsuario().toPromise()
      .then(data => {
        this.UsuariosLista = data;
        this.Usuario = this.UsuariosLista.find(x => Number(x.idTipoUsuario) === Number(this.usuarioTipo));
        this.usuarioText = this.Usuario.descripcion;
        this.UsuarioIcono = this.Usuario.icono;
        this.spinner.hide();
        //console.log(this.GrupoLista);
      })
  }

  uCuenta;
  uCuentaNota;
  uClave;
  uClavePH;
  className;
  classVisita;
  classCuenta;
  classClave;
  setLabels() {
    this.uCuentaNota = '';
    this.uCuenta = 'Cuenta';
    this.uClave = 'Contraseña';
    this.uClavePH = this.uClave;
    this.className = 'hide';
    this.classVisita = 'hide';
    this.classClave = '';
    this.classCuenta = '';
    let id = this.usuarioTipo;
    let cuentasExterno = this.cuentasExterno.split(',');
    if (cuentasExterno.includes(id.toString())) { //Honorarios, concesionarios, etc.
      this.uCuenta = 'Usuario';
      this.uClave = 'Contraseña';
      this.uClavePH = this.uClave;
      //this.classClave = 'hide'; //Ocultar el campo
    }

    if (id == 34) { //Sin Registro
      this.uCuentaNota = ' sin dígito verificador';
    }

    if (id == 101) { //Compras
      this.uCuenta = 'RFC-Empresa';
      this.uClave = 'Contraseña Portal';
      this.uClavePH = 'Contraseña Portal de Proveedores';
      this.className = '';
    }

    if (id == 102) { //Sin Registro
      this.uCuenta = 'Folio';
      //this.uClave = 'Código-Validación';
      this.uClavePH = this.uClave;
      //this.className = '';      
    }

    if (id == 104 || id == 105 || id == 110) { //Sin Registro
      this.uCuenta = 'Folio';
      //this.uClave = 'Código-Validación';
      this.uClavePH = this.uClave;
      this.className = '';
      this.classVisita = '';
      this.classCuenta = 'hide';
    }
  }


  /*
  setTipoUsuario(usuarioTipo){
    //this.usuarioImg = 'personal';
    if(usuarioTipo==1) this.usuarioText = 'personal';
    if(usuarioTipo==2) this.usuarioText = 'estudiante';
    if(usuarioTipo==3) this.usuarioText = 'proveedores';
    if(usuarioTipo==4) this.usuarioText = 'visitante';
  }
  */

  //sitio;
  logo;
  bgapp;
  setSitio(sitio) {
    //this.sitio = 1;
    this.bgapp = '';
    if (sitio == 1) { //1 - CDMX
      this.logo = 'ibero-090.svg';
      this.bgapp = 'bg-ibero-rojo';
    }
    if (sitio == 2) { //2 - PREPA
      this.logo = 'logo-prepa.svg';
      this.bgapp = 'bg-ibero-rojo';
    }
    if (sitio == 3) { //3 - TIJUANA
      this.logo = 'ibero-099.svg';
      this.bgapp = '';
    }
  }

  ngOnInit() {
    //this.modal.open(this.anuncio, { size: 'xl' });
    localStorage.setItem('csh', '');
    if (config.clientKey != "") {
      console.log(config);
      this.recaptcha3.init(config.clientKey).then(status => {
        // status: success/error
        // success - script is loaded and greaptcha is ready
        // error - script is not loaded
        //console.log(status)
        if (status == 'error') {
          this.toastr.error('No se cargó el script. Intente de nuevo más tarde.');
        }
      });
    }
    document.getElementById("cuenta")?.focus();
    this.getGrupoLista();
    this.iniciarForm();
  }

  @ViewChild('anuncio') anuncio: TemplateRef<any>;
  ngAfterViewInit() {
    //this.modal.open(this.anuncio, { size: 'xl' });
  }

  iniciarForm() {
    this.flogin = this.fb.group({
      cuenta: ['', Validators.required],
      clave: ['', Validators.required],
      codigoValidacion: [''],
      token: [''],
      tipo: [0],
      idTipoUsuario: [0],
      digito: [''],
      recordar: [false],
      nombres: [''],
      apellidoPaterno: [''],
      apellidoMaterno: [''],
      destinoVisita: [''],
      motivoVisita: [''],
      autorizaAcceso: [''],
      plantel: ['']

      //,recaptcha: new FormControl('', Validators.required),
    });
    //console.log(this.flogin);
    //this.flogin.recordar = false;
    let cov19rec = localStorage.getItem('cov19rec');
    //alert(cov19rec);
    //alert(this.usuarioTipo);
    //if(cov19rec == 'true'){
    let sitio = this.usuarioService.getSitio();
    this.flogin.cuenta = localStorage.getItem('cuenta');
    this.flogin.digito = localStorage.getItem('digito');
    this.flogin.clave = localStorage.getItem('clave');
    this.flogin.recordar = Boolean(localStorage.getItem('cov19rec'));

    this.vSitio(sitio, this.usuarioTipo);


    //}
    this.mlogin = this.flogin;
  }

  vSitio(sitio, idTipoUsuario) {
    if (sitio == 3) { //3 - TIJUANA 
      let cuentasTJold = '8,21,22,24,104';
      let cuentasValidar = cuentasTJold.split(',');
      if (cuentasValidar.includes(idTipoUsuario)) {
        localStorage.setItem('cov19rec', 'false');
        this.router.navigate(['/perfil']);
      }
    }
  }

  GrupoLista;
  getGrupoLista() {
    this.spinner.show();
    this.grupoService.getGrupo().toPromise()
      .then(data => {
        this.GrupoLista = data;
        this.spinner.hide();
        //console.log(this.GrupoLista);
      })
  }

  getChecks() {
    var choices = [];
    var els = document.getElementsByName('tipo');
    for (var i = 0; i < els.length; i++) {
      if (els[i]['checked']) {
        choices.push(els[i].id);
      }
    }
    //console.log(choices.toString());
    return choices.toString();
  }

  cambio(valor) {
    //this.tipo = valor;
    this.mlogin.tipo = valor;
    if (valor !== 2) {
      this.mlogin.digito = '';
    }
    //alert(this.tipo);
  }

  Cuenta: any;
  CuentaLista: any;
  Empleado;
  EmpleadoLista;
  cuenta;
  clave;
  tipo;
  tokenCaptcha;
  entrar() {
    this.spinner.show();
    let recordar = this.flogin.controls["recordar"].value;
    if (recordar) {
      localStorage.setItem('cov19rec', 'true');
      localStorage.setItem('cuenta', this.flogin.controls["cuenta"].value);
      localStorage.setItem('digito', this.flogin.controls["digito"].value);
      localStorage.setItem('clave', this.flogin.controls["clave"].value);
    }
    else {
      localStorage.setItem('cov19rec', '');
      localStorage.setItem('cuenta', '');
      localStorage.setItem('digito', '');
      localStorage.setItem('clave', '');
    }

    if (this.flogin.controls["apellidoMaterno"].value == undefined) this.flogin.controls["apellidoMaterno"].setValue("");
    if (this.flogin.controls["apellidoPaterno"].value == undefined) this.flogin.controls["apellidoPaterno"].setValue("");
    if (this.flogin.controls["autorizaAcceso"].value == undefined) this.flogin.controls["autorizaAcceso"].setValue("");
    if (this.flogin.controls["clave"].value == undefined) this.flogin.controls["clave"].setValue("");
    if (this.flogin.controls["cuenta"].value == undefined) this.flogin.controls["cuenta"].setValue("");
    if (this.flogin.controls["destinoVisita"].value == undefined) this.flogin.controls["destinoVisita"].setValue("");
    if (this.flogin.controls["digito"].value == undefined) this.flogin.controls["digito"].setValue("");
    if (this.flogin.controls["motivoVisita"].value == undefined) this.flogin.controls["motivoVisita"].setValue("");
    if (this.flogin.controls["nombres"].value == undefined) this.flogin.controls["nombres"].setValue("");

    //Copiamos la clave en el código de validación
    this.clave = this.flogin.controls["clave"].value;
    this.cuenta = this.flogin.controls["cuenta"].value;
    this.flogin.controls["codigoValidacion"].setValue(this.clave);
    this.flogin.controls["tipo"].setValue(this.usuarioTipo);
    this.flogin.controls["idTipoUsuario"].setValue(this.usuarioTipo);
    this.flogin.controls["plantel"].setValue(this.usuarioService.getSitio());

    //console.log(this.flogin.value);
    //generate new token
    if (config.clientKey != "") {
      this.recaptcha3.getToken().then(token => {
        this.tokenCaptcha = token;
        this.flogin.controls["token"].setValue(this.tokenCaptcha);
        this.usuarioService.loginAccount(this.flogin.value).toPromise()
          .then(data => {
            this.Cuenta = data;
            console.log(this.Cuenta);
            this.spinner.hide();
            this.login();
          })

      }, error => {
        // get error, e.g. if key is invalid
        this.toastr.error(error);
      });
    }
    else {
      this.tokenCaptcha = ""
      this.usuarioService.loginAccount(this.flogin.value).toPromise()
        .then(data => {
          this.Cuenta = data;
          console.log(this.Cuenta);
          this.spinner.hide();
          this.login();
        });
    }
  }

  login() {
    console.log("cuenta:");
    console.log(this.cuenta);
    //this.usuarioService.addUser(this.Cuenta);
    //localStorage.setItem('valido', '1');
    //this.router.navigate(['/e-app']);
    //return false;
    //console.log(this.Cuenta); DAAE
    if (this.Cuenta.validoExterno == true) {
      this.Cuenta["grupoNombre"] = this.usuarioText;
      this.Cuenta["codigoValidacion"] = this.clave;

      if (this.Cuenta["tipo"] == "101") {
        this.Cuenta["nombres"] = this.flogin.controls["nombres"].value;
        this.Cuenta["apellido_Materno"] = this.flogin.controls["apellidoMaterno"].value;
        this.Cuenta["apellido_Paterno"] = this.flogin.controls["apellidoPaterno"].value;
      }

      this.usuarioService.addUser(this.Cuenta);
      localStorage.setItem('valido', '1');
      console.log("ingresado");
      console.log(this.Cuenta.nombres + ' ' + this.Cuenta.apellido_Paterno + ' ' + this.Cuenta.apellido_Materno);
      this.uiFullNameService.InicializarNombre(this.Cuenta.nombres + ' ' + this.Cuenta.apellido_Paterno + ' ' + this.Cuenta.apellido_Materno);

      this.usuarioService.addUser(this.Cuenta); //DAAE
      //Si es guardia
      if (this.usuarioTipo == 103) {
        this.router.navigate(['/guardia']);
      }
      else {
        if (this.Cuenta.vigente == false) {
          this.router.navigate(['/e-app']);
        }
        else {
          let vg = 'Usted ha respondido el cuestionario, podrá realizarlo de nuevo después del';
          //Cuestionario ya fue contestado
          localStorage.setItem('csh', '1');
          this.toastr.error(vg + ' ' + this.formatDate(this.Cuenta.fechaVigencia));
          this.encuestaService.addEDatos(this.Cuenta.encuesta);
          this.router.navigate(['/e-resultado/']);
          return false;
        }
      }
    }
    else {
      //this.toastr.warning(this.Cuenta.validoExternoRespuesta);
      this.toastr.warning('Datos Incorrectos');
      return false;
    }
  }

  regresar() {
    localStorage.setItem('noredirect', 'true');
    this.router.navigate(['/perfil']);
  }

  public ngOnDestroy() {
    this.recaptcha3.destroy();
  }

  formatDate(fecha) {
    var d = new Date(fecha);
    d.setHours(0, 0, 0, 0);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  alphanumericOnly(e) {
    var value = e.target.value;
    //only allow a-z, A-Z, digits 0-9 and comma, with only 1 consecutive comma ...
    if (!e.key.match(/[a-zA-Z0-9]/)) {
      e.preventDefault();
    }
  }

  ap=true;

  showap(content){
    this.modal.open(content,{size:'xl'});
  }

}
