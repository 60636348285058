import { UiFullNameHeaderService } from './../../../data-access/ui-full-name-header.service';
//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { EncuestaService } from 'src/app/data-access/encuesta.service';
import { AppService } from 'src/app/data-access/app.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';
import { AuthService } from 'src/app/data-access/auth.service';
//import {NgbCalendar, NgbDate} from "@ng-bootstrap/ng-bootstrap";

class EM {
  IdApp: string = "";
  IdTipoUsuario: number;
  GrupoNombre: string;
  Cuenta: string;
  CodigoValidacion: string;
  Nombres: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;
  Respuestas: string;
  Resultado: boolean;
  Placas: string;
  Observaciones: string;
  Plantel: number;
}


@Component({
  selector: 'app-e-app',
  templateUrl: './e-app.component.html',
  styles: [
  ]
})
export class EApp implements OnInit {
  fprincipal: FormGroup;
  fperfil: FormGroup;
  ffiltro: FormGroup;
  mprincipal;
  mperfil;
  mfiltro;
  PerfilLista;
  subscripcion: Subscription;
  demo;
  redirectURL: string | undefined;
  constructor(
    private authService: AuthService,
    private router: Router,
    public fb: FormBuilder,
    public usuarioService: UsuarioService,
    private encuestaService: EncuestaService,
    private appService: AppService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal,
    public uiFullName: UiFullNameHeaderService,
  ) {
    if (!authService.isAuthenticated) {
      router.navigate(['/login'])
    }
    
    this.iniciarForm();


  }

  sUsuario;
  usuarioTipo;
  usrVisita = undefined;
  sitio;
  logo;
  bgapp;
  bgtext;
  NombreCompleto;
  ngOnInit(): void {
    let user = this.usuarioService.getUser();

    //console.log(user); 

    this.NombreCompleto = user.nombres + ' ' + user.apellido_Paterno + ' ' + user.apellido_Materno;

    this.logo = 'ibero-090.svg';
    this.bgapp = 'bg-ibero-rojo';
    this.bgtext = '';

    this.addLista();
    //this.sort();
    let csh = localStorage.getItem('csh');
    if (csh == '1') this.router.navigate(['/login']);

    this.usuarioTipo = localStorage.getItem('usuarioTipo');
    //Validamos si es el registro de un visitante hecho por un guardia
    if (this.usuarioTipo == 103 || this.usuarioTipo == 104 || this.usuarioTipo == 110 || this.usuarioTipo == 105) {
      this.usrVisita = this.usuarioService.getVisita();
    }
    this.sUsuario = this.usuarioService.getUser();




  }

  iniciarForm() {
    this.fprincipalIniciar();
  }

  fprincipalIniciar() {
    //Principal
    this.fprincipal = this.fb.group({
      r1: [undefined, Validators.required],
      r2: [undefined, Validators.required],
      r3: [undefined, Validators.required]
    });
    this.mprincipal = this.fprincipal;
  }

  Apps;
  AppsLista;
  addLista() {
    this.spinner.show();
    this.appService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.Apps = data;
        this.AppsLista = this.Apps.filter(x => x.activo === true);

        this.redirectURL = localStorage.getItem('redirectURL');

        if (this.redirectURL != "undefined") {
          let idApp = this.redirectURL.split('_')[0];
          let realredirectURL = this.redirectURL.split('_')[1];
          let app = this.AppsLista.filter(x => x.idApp == idApp)[0].url;
          let url = app + this.sUsuario.urlDatos + "_redirectURL_" + realredirectURL;
          console.log(url);
          window.location.href = app + this.sUsuario.urlDatos + "_redirectURL_" + realredirectURL;
        }

        console.log(this.AppsLista);

        let usuario = this.usuarioService.getUser();
        console.log(usuario);

        let ID_TIPO_DE_USUARIO_PERSONAL = "24";
        let ID_APP_AUDIO_Y_VIDEO = 1;

        if (usuario.tipo != ID_TIPO_DE_USUARIO_PERSONAL) {
          this.AppsLista = this.Apps.filter(x => x.activo === true && x.idApp != ID_APP_AUDIO_Y_VIDEO);
        }

      });
  }

  openApp(url) {
    //window.open('http://localhost:4200/#/inicio/' + encodeURIComponent(this.sUsuario.urlDatos));
    window.open(url + this.sUsuario.urlDatos);

  }

  logout() {
    this.clear();
    this.router.navigateByUrl('/');
  }

  clear() {
    localStorage.removeItem('user');
  }


}

