import { Component, OnInit } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-e-set',
  templateUrl: './e-set.component.html',
  styles: [
  ]
})
export class ESetComponent implements OnInit {
 

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
    ) { }


  usuarioTipo;
  antUsuarioTipo;
  ngOnInit(): void {
    
    this.antUsuarioTipo = localStorage.getItem('usuarioTipo');
    localStorage.setItem('usuarioTipo', '24');
    this.usuarioTipo = localStorage.getItem('usuarioTipo');
  }

}
