<nav class="navbar navbar-expand-lg navbar-light" [ngClass]="bgapp" id="nav">
  <div class="container" >
    <a class="navbar-brand" [routerLink]="" (click)="home()">
      <img src="assets/img/login/{{logo}}" width="120">
    </a>
    <p justify-content-end>
    <button type="button" class="btn btn-danger mr-4" (click)="logout();">Salir</button>
    </p>
  </div>
  </nav>
