<div class="bg-login">
  <div class="container">
    <div class="row align-items-center minh-100">
      <div class="col-md-12">
        <div class="card shadow-lg border-0 rounded-lg mt-5" id="box-login">
          <!--div class="card-header text-center" [ngClass]="bgapp">
            <img src="../../../../assets/img/login/{{logo}}" class="img-login" id="img-login" />
          </div-->
          <div class="card-body">
            <form [formGroup]="flogin" >
              <div class="form-group text-center" >
                <button class="btn icon-btn mb-4" ngbTooltip="">
                  <la-icon [icon]="UsuarioIcono" size="2x" class="img-box align-middle p-3 item-image bg-white rounded text-danger"></la-icon>
                </button>
                <p class="text-danger item-text" >{{usuarioText}}</p>
              </div>

              <div id="divNombre">
                <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                  <div class="col-1">
                    <label class="mb-1" for="nombres"><span class="text-danger">*</span>Nombre</label>
                  </div>
                  <div class="col-11">
                      <input class="form-control py-2" id="nombres" type="text" placeholder="Nombres"
                      formControlName="nombres" [(ngModel)]="mlogin.nombres" />
                      <div *ngIf="flogin.controls['nombres'].touched && flogin.controls['nombres'].errors?.required"
                      class="text-danger">El nombre es requerido</div>                      
                  </div>                  
                </div>
                
                <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                  <div class="col-1">&nbsp;</div>
                  <div class="col-11">
                      <input class="form-control py-2" id="apellidoPaterno" type="text" placeholder="Apellido Paterno"
                      formControlName="apellidoPaterno" [(ngModel)]="mlogin.apellidoPaterno" />
                      <div *ngIf="flogin.controls['apellidoPaterno'].touched && flogin.controls['apellidoPaterno'].errors?.required"
                      class="text-danger">El apellido paterno es requerido</div>                       
                  </div>              
                </div>
                
                <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                  <div class="col-1">&nbsp;</div>
                  <div class="col-11">
                      <input class="form-control py-2" id="apellidoMaterno" type="text" placeholder="Apellido Materno"
                      formControlName="apellidoMaterno" [(ngModel)]="mlogin.apellidoMaterno" />
                      <div *ngIf="flogin.controls['apellidoMaterno'].touched && flogin.controls['apellidoMaterno'].errors?.required"
                      class="text-danger">El apellido materno es requerido</div>                         
                  </div>              
                </div>               
  
              </div>
              <div id="divVista">
              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">
                  <label class="mb-1" for="destinoVisita"><span class="text-danger">*</span>Lugar</label>
                </div>
                <div class="col-11">
                    <input class="form-control py-2" id="destinoVisita" type="text" placeholder="¿A dónde se dirige?"
                    formControlName="destinoVisita" [(ngModel)]="mlogin.destinoVisita" />
                    <div *ngIf="flogin.controls['destinoVisita'].touched && flogin.controls['destinoVisita'].errors?.required"
                    class="text-danger">El destino de visita es requerido</div>                      
                </div>              
              </div>
  
              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">
                  <label class="mb-1" for="motivoVisita"><span class="text-danger">*</span>Motivo</label>
                </div>
                <div class="col-11">
                    <input class="form-control py-2" id="motivoVisita" type="text" placeholder="Motivo de la visita"
                    formControlName="motivoVisita" [(ngModel)]="mlogin.motivoVisita" />
                    <div *ngIf="flogin.controls['motivoVisita'].touched && flogin.controls['motivoVisita'].errors?.required"
                    class="text-danger">El motivo de visita es requerido</div>                     
                </div>              
              </div>
              
              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">
                  <label class="mb-1" for="autorizaAcceso"><span class="text-danger">*</span>Visita a</label>
                </div>
                <div class="col-11">
                    <input class="form-control py-2" id="autorizaAcceso" type="text" placeholder="Persona que visita"
                    formControlName="autorizaAcceso" [(ngModel)]="mlogin.autorizaAcceso" />
                    <div *ngIf="flogin.controls['autorizaAcceso'].touched && flogin.controls['autorizaAcceso'].errors?.required"
                    class="text-danger">La persona que visita es requerido</div>
                </div>              
              </div>
              
              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">
                  <label class="mb-1" for="">Placas</label>
                </div>
                <div class="col-11">
                    <input class="form-control py-2" id="placas" type="text" placeholder="Placas (en su caso)"
                    formControlName="placas" [(ngModel)]="mlogin.placas" />
                </div>              
              </div> 

              <div class="row text-dark form-group d-flex align-items-center text-left mt-2">
                <div class="col-1">
                  <label class="mb-1" for="observaciones">Observación</label>
                </div>
                <div class="col-11">
                    <input class="form-control py-2" id="observaciones" type="text" placeholder="Observaciones"
                    formControlName="observaciones" [(ngModel)]="mlogin.observaciones" />
                </div>              
              </div>               

            </div>
  

              <div class="form-group d-flex align-items-center text-left mt-2">
                <a href="https://ibero.mx/aviso-legal-y-de-privacidad" 
                target="_blank" class="text-ibero">Ver Aviso de Privacidad</a>
              </div>            
  
              <div class="form-group d-flex align-items-center justify-content-center mt-4 mb-0">
              <button *ngIf="usuarioTipo==104 || usuarioTipo==105 || usuarioTipo==110" class="btn btn-danger text-white mr-2" (click)="regresar()" >Regresar</button>
              <button class="btn btn-danger text-white" (click)="entrar()" [disabled]="flogin.invalid">Entrar</button>
              </div>
  
  
  
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
      <!-- Anuncio  -->
      <ng-template #anuncio let-modal>
        <div class="modal-hidden">
          <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    
        <div class="modal-body">
          <div class="card border-dark mb-0">
            <div class="card-header text-white text-center bg-ibero-rojo">&nbsp;</div>
            <div class="card-body text-dark p-0 text-center">
              <img class="anuncio_login" src="../../../../assets/img/login/anuncio003.svg" />
  
            </div>
          </div>
        </div>
    </ng-template>