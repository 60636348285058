import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { NgxCaptureService } from 'ngx-capture';
import { tap } from "rxjs/operators";
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EncuestaService } from 'src/app/data-access/encuesta.service';
import { UsuarioService } from 'src/app/data-access/usuario.service';

class EM
{
  IdEncuesta: string ="";
  IdTipoUsuario: number;
  GrupoNombre: string;
  Cuenta: string;
  //Clave: string;
  CodigoValidacion: string;
  Nombres: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;
  DestinoVisita: string;
  MotivoVisita: string;
  AutorizaAcceso: string;    
  Respuestas: string;
  Resultado: boolean;
  Placas:string;
  Observaciones:string;
  Plantel: number;
}

@Component({
  selector: 'app-e-resultado',
  templateUrl: './e-resultado.component.html',
  styles: [
  ]
})
export class EResultadoComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = 'https://ibero.mx';

  @ViewChild('screen', { static: true }) screen: any;


  display = true;
  href : string;
  qrColor: string;

  constructor(private activatedRoute: ActivatedRoute, 
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private captureService:NgxCaptureService,
    private location: Location,
    public us: UsuarioService,
    private encuestaService: EncuestaService
    ) { }


  res;
  res_texto;
  res_qrcode;
  //res_acceso;
  qacceso;
  url_location;

  resultado;
  qresultado;
  vigencia;
  qvigencia;
  acceso;
  //qacceso;
  
 
  //sUsuario;
  nombreCompleto;
  grupo;
  cuenta;
  fechaRegistro;
  idEncuesta;
  misma;
  gAutorizaAcceso;
  idTipoUsuario;
  ngOnInit(): void {
    let res = null;
    let sitio = localStorage.getItem('usuarioSitio');
    let plantel = "";
    if(sitio == "1") plantel = "la IBERO";
    if(sitio == "2") plantel = "Prepa Ibero";
    if(sitio == "3") plantel = "la IBERO";
    //res = localStorage.getItem('res');
    //console.log(res);
    //res = JSON.parse(localStorage.getItem('res')) || [];



    //localStorage.setItem('csh', '1');
    
    res = this.encuestaService.getEDatos();
    this.idTipoUsuario = res.idTipoUsuario;

    let valido = localStorage.getItem('valido');
    if((this.idTipoUsuario!=104 && this.idTipoUsuario!=105 && this.idTipoUsuario!=110) && valido=='0'){
      this.logout();
    }

    this.misma=true;
    this.gAutorizaAcceso = res.autorizaAcceso;
    this.autorizaAcceso = this.gAutorizaAcceso;
    //alert(this.autorizaAcceso);
    this.idEncuesta = res.idEncuesta;
    
    //console.log(res);
    //alert(res);
    this.url_location =  window.location.protocol + '//' + window.location.host + '/#/e-consulta/';
    if(res.resultado){
      this.qrColor = '#008000';
      this.value = this.url_location + res.idEncuesta ;
      this.qacceso = 'text-success';
      //this.res_acceso = 'Acceso Permitido';
      this.res_texto = 'Gracias por sus respuestas, usted podrá ingresar a las instalaciones de ' + plantel + '.';
      this.res_qrcode = 'Deberá mostrar el código QR al personal ubicado en las puertas de acceso habilitadas.';
    }
    else{
      this.qrColor = '#ff0000';
      this.value = this.url_location + res.idEncuesta ;
      this.qacceso = 'text-danger';
      //this.res_acceso = 'Acceso No Permitido';
      this.res_texto = 'Tus respuestas indican un riesgo para tu salud y para las y los demás, te recomendamos permanecer en casa y reportarte.';
      this.res_qrcode = '';
    }
    
    this.display = true; 

    //Información Completa
    this.resultado = 'No Seguro';
    this.qresultado = 'text-danger';       
    if(Boolean(JSON.parse(res["resultado"]))){
      this.resultado = 'Seguro';
      this.qresultado = 'text-success';
    }
    
    this.vigencia = 'Expirado';
    this.qvigencia = 'text-danger';        
    if(Boolean(JSON.parse(res["acceso"]))){
      this.vigencia = "Vigente";
      this.qvigencia = 'text-success';
    }

    this.acceso = "No Permitido";
    this.qacceso = 'text-danger';        
    if(Boolean(JSON.parse(res["resultado"])) && Boolean(JSON.parse(res["acceso"]))){
      this.acceso = "Permitido";
      this.qacceso = 'text-success';
    }
    else{
      //Si el acceso es NO PERMITIDO, mostrar la vigencia en rojo
      this.qvigencia = 'text-danger';          
    }

    this.fechaRegistro = this.formatDate(res["fechaRegistro"]);
    this.vigencia = this.formatDate(res["fechaVigencia"]);
    this.nombreCompleto = res["nombres"] + ' ' + res["apellidoPaterno"] + ' ' + res["apellidoMaterno"];
    this.grupo = res["grupoNombre"];
    this.cuenta = res["cuenta"];    


    //console.log(this.value);
    //alert(window.location.host);

  }

  

  //img = "";
  imgBase64:any='';
  //capture(){
  downloadImage(){
    /*
    this.captureService.getImage(this.screen.nativeElement, true).then(img=>{
      console.log(img);
    });
    */
    this.captureService
      .getImage(this.screen.nativeElement, true)
      .pipe(
        tap(img => {
          //this.img = img;
          this.imgBase64=img;
          //this.href = this.img;
          const file = this.DataURIToBlob(this.imgBase64);


          const anchor = document.createElement('a');
          anchor.download = "qr_acceso.png";
          anchor.href = (window.webkitURL || window.URL).createObjectURL(file);
          anchor.click();

          //this.href = file;
          //this.href = 'data:application/octet-stream;base64,' + file;
          //console.log(file);
        })
      )
      .subscribe();
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)
      
        return new Blob([ia], { type: mimeString })
  }  

  /*
  downloadImage(){
    let arrSrc = document.getElementsByTagName('img');
    this.href = document.getElementsByTagName('img')[arrSrc.length - 1].src;
    
    ///this.href = (document.getElementById('QRCode') as HTMLImageElement).src;
    //this.href = document.getElementsByTagName('img')[1].src;
  }
  */
  personaVisita(values:any){
    let state = values.currentTarget.checked
    this.misma = state;
    //alert(values.currentTarget.checked);
    //console.log(this.misma);
    if(state){
      //let res = this.encuestaService.getEDatos();
      this.autorizaAcceso = this.gAutorizaAcceso;
    }
    else{
      this.autorizaAcceso = "";
    }
  }

  formatDate(fecha) {
    var d = new Date(fecha);
    d.setHours(0, 0, 0, 0);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  } 
  
  onBeforeUnload(){
    //Si no son guardias, cerramos la sesión
    if(this.idTipoUsuario!=103){
      this.logout();
    }
  }

  logout() {
    localStorage.setItem('valido', '0');
    localStorage.setItem('user', '');
    localStorage.setItem('csh', '');
    //Si es visitante, lo enviamos a la pantalla de registro
    if(this.idTipoUsuario!=104 && this.idTipoUsuario!=105 && this.idTipoUsuario!=110){
      this.router.navigate(['/login']);
    }
    else{
      this.router.navigate(['/guardia']);
    }
    
  }

  inicio() {
    this.router.navigate(['/guardia']);
  }

  addEncuesta;
  sUsuario;
  autorizaAcceso;
  registrar(){
    this.spinner.show();
    this.addEncuesta = new EM();
    this.sUsuario = this.us.getUser();
    this.addEncuesta.IdEncuesta = this.idEncuesta;
    this.addEncuesta.Cuenta = this.sUsuario.cuenta;
    this.addEncuesta.AutorizaAcceso = this.autorizaAcceso;
    //this.addEncuesta.Cuenta = this.autorizaAcceso;

    //console.log(this.addEncuesta);
    //return false;
    //Visitante
    this.encuestaService.registroIngreso(this.addEncuesta).subscribe(
      (res) => {  
        this.encuestaService.addEDatos(res);
        //this.router.navigate(['/e-resultado/']);
        this.spinner.hide();
        this.toastr.success('El ingreso ha sido registrado');
        this.router.navigate(['/guardia']);
      },
      (error) => {
        console.log(error);
        if(error.message=="Unauthorized")
        {
          this.router.navigate(['/login']);
        }
      });

  }  

}
