import { Component } from '@angular/core';
//import { LoginService } from './core/services/login.service';
import { LoginService } from './data-access/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ClientApp';
  validar = false;

  constructor(
    public ls: LoginService,
    private router: Router,
    private location: Location
  ) {

  }

  ngOnInit() {
    /*
    this.router.url;
    this.location.path;
    alert(this.router.url);
    alert(this.location.path);
    */
    let path = this.location.path();
    if(path.indexOf('/e-consulta/')==0){
      localStorage.setItem('valido', '1');
    }
    let valor = localStorage.getItem('valido');

    if (valor == '1') {
      this.validar = true;
    }

  }

}