import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { config } from './config';
const AUTHORIZATION_HEADER = 'autorization';

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {
  user = [];
  constructor(private http: HttpClient) {
  }
    //Consulta
    getEncuestaById(id:undefined) {
      return this.http.get(config.apiUrl + '/Encuesta/'+id);
    }

    //Alta
    addEncuesta(data:undefined): Observable<any> {
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      let tokenParse = usr.token;
      let header = new HttpHeaders().set("Authorization", 'Bearer ' + usr.token);
      let headers = new  HttpHeaders();
      headers.append('Authorization', `  ${tokenParse}`);
      return this.http.post(config.apiUrl + '/Encuesta/', data, { headers: header } );
    }

    //Visitante_auth
    addVisitante(data): Observable<any> {
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      let tokenParse = usr.token;
      let header = new HttpHeaders().set("Authorization", 'Bearer ' + usr.token);
      let headers = new  HttpHeaders();
      headers.append('Authorization', `  ${tokenParse}`);      
      if(data.Cuenta=="0"){
        return this.http.post(config.apiUrl + '/Encuesta/Visitante/', data);
      }
      else
      {
        return this.http.post(config.apiUrl + '/Encuesta/Visitante_auth/', data, { headers: header } );
      }
      
    }
    //registrar visita
    registroIngreso(data): Observable<any> {
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      let tokenParse = usr.token;
      let header = new HttpHeaders().set("Authorization", 'Bearer ' + usr.token);
      let headers = new  HttpHeaders();
      headers.append('Authorization', `  ${tokenParse}`); 
      return this.http.post(config.apiUrl + '/Encuesta/RegistraIngreso/', data, { headers: header } );
      
    }    

    addEDatos(res) {
      localStorage.setItem('res', JSON.stringify(res));
    }

    getEDatos() {
      return JSON.parse(localStorage.getItem('res') || '{}') || [];
    }    

}
