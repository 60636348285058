import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxCaptureModule } from 'ngx-capture';

import { AngularLineawesomeModule, LaIconLibrary } from 'angular-line-awesome';
import { lasSave, lasEllipsisH, 
  lasUsers, lasUserShield, lasUserCog, lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
  lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe, 
  lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher, lasBriefcase, lasEnvelope, lasAddressBook, 
  lasMoneyCheckAlt, lasQuestion, lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, 
  lasTools, lasUser, lasBookOpen, lasBook, lasHeartbeat, lasUniversity, lasSignOutAlt
 } from 'angular-line-awesome/icons';

import { EApp } from './e-app/e-app.component';
import { EResultadoComponent } from './e-resultado/e-resultado.component';
import { EConsultaComponent } from './e-consulta/e-consulta.component';
import { ESetComponent } from './e-set/e-set.component';
import { AuthService } from 'src/app/data-access/auth.service';


@NgModule({
  declarations: [EApp,  EResultadoComponent, EConsultaComponent, ESetComponent],
  imports: [
    CommonModule,
    //FontAwesomeModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgxQRCodeModule,
    NgxCaptureModule,
    AngularLineawesomeModule
  ],
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter
    }, 
    AuthService
  ],
})
export class EstudianteModule {
  constructor(library: LaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons([lasSave, lasEllipsisH, 
      lasUsers, lasUserShield, lasUserCog, 
      lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
      lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe,
      lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher,
      lasBriefcase, lasEnvelope, lasAddressBook,
      lasMoneyCheckAlt, lasQuestion, lasShieldAlt,
      lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, lasTools,
      lasUser, lasUserShield, lasBookOpen, lasBook, lasHeartbeat, lasUniversity, lasSignOutAlt
    ]);
  }  

}
