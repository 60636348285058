import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiFullNameHeaderService {

  private fullName: string;
  private subject = new Subject<any>();

  constructor() { }

  InicializarNombre(nombre: string): void {
    console.log("fullName:" + nombre);
    this.fullName = nombre;
    this.subject.next(this.fullName);
  }

  Subscribirse(): Observable<any> {
    return this.subject.asObservable();
  }
}
