import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../data-access/config';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  user = [];
  constructor(private http: HttpClient) {
  }

  //Login Cuenta
  loginAccount(data:undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Usuario/LoginCuenta/', data);
  }

  addUser(usuario:undefined) {
    localStorage.setItem('user', JSON.stringify(usuario));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user') || '{}') || [];
  }

  addVisita(usuario) {
    localStorage.setItem('visita', JSON.stringify(usuario));
  }

  getVisita() {
    return JSON.parse(localStorage.getItem('visita') || '{}') || [];
  }    

  getSitio() {
    return config.plantel;
  }

  ex() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return (usr.usuario_tipoId === 2)
  }

  in() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return (usr.usuario_tipoId === 1)
  }

  adm() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return (usr.perfilId === 1)
  }

  ser() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return (usr.perfilId === 3)
  }

  grd() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    return usr.tipo == "103";
    //return (usr.usuario_tipoId === 2)
  }  

  anony() {
    let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
    //console.log(usr);
    return usr.tipo == undefined;
  }   

  usr() {
    let user = JSON.parse(localStorage.getItem('user') || '{}') || [];
    //return usr.nombre + ' ' + usr.apPat;
    //return user.nombre + ' ' + user.apPat + ' ' + user.apMat;
    return user.nombres + ' ' + user.apellido_Paterno + ' ' + user.apellido_Materno;
  }

}
