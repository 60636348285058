import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './feature/shell/login/login.component';
import { EApp } from './feature/estudiante/e-app/e-app.component';
import { EResultadoComponent } from './feature/estudiante/e-resultado/e-resultado.component';
import { EConsultaComponent } from './feature/estudiante/e-consulta/e-consulta.component';
import { PerfilComponent } from './feature/shell/perfil/perfil.component';
import { GuardiaComponent } from './feature/shell/guardia/guardia.component';
import { ESetComponent } from './feature/estudiante/e-set/e-set.component';
import { NotFoundComponent } from './not-found/not-found.component';


const routes: Routes = [
  //{ path: '', component: LoginComponent},
  { path: '', component: PerfilComponent },
  { path: 'perfil', component: PerfilComponent },
  { path: 'login', component: LoginComponent },
  { path: 'guardia', component: GuardiaComponent },
  { path: 'e-app', component: EApp },
  { path: 'e-resultado', component: EResultadoComponent },
  { path: 'e-consulta/:id', component: EConsultaComponent },
  { path: 'e-set', component: ESetComponent },
  { path: '*', component: PerfilComponent },
  { path: '**', component: NotFoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
