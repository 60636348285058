import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './login/login.component';
import { PerfilComponent } from './perfil/perfil.component';
import { GuardiaComponent } from './guardia/guardia.component';

import { AngularLineawesomeModule, LaIconLibrary } from 'angular-line-awesome';
import { lasSave, lasEllipsisH, 
  lasUsers, lasUserShield, lasUserCog, lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
  lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe, 
  lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher, lasBriefcase, lasEnvelope, lasAddressBook, 
  lasMoneyCheckAlt, lasQuestion, lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, 
  lasTools, lasUser, lasBookOpen
 } from 'angular-line-awesome/icons';

@NgModule({
  declarations: [FooterComponent, NavMenuComponent, LoginComponent, PerfilComponent, GuardiaComponent],
  imports: [
	  CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AngularLineawesomeModule
  ],
  exports: [FooterComponent, NavMenuComponent, LoginComponent, PerfilComponent, GuardiaComponent]
})
export class ShellModule { 
  constructor(library: LaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons([lasSave, lasEllipsisH, 
      lasUsers, lasUserShield, lasUserCog, 
      lasCheckCircle, lasCube, lasCubes, lasShieldAlt, lasCog,
      lasMicrophone, lasLanguage, lasGraduationCap, lasAppleAlt, lasGlasses, lasGlobe,
      lasHome, lasCity, lasSchool, lasAtom, lasChalkboardTeacher,
      lasBriefcase, lasEnvelope, lasAddressBook,
      lasMoneyCheckAlt, lasQuestion, lasShieldAlt,
      lasAddressCard, lasLaptop, lasShippingFast, lasHandshake, lasParking, lasTools,
      lasUser, lasUserShield, lasBookOpen
    ]);
  }  
}
