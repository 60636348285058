<div class="bg-login">
  <div class="container">
    <div class="row align-items-center minh-100">
      <div class="col-md-12">
        <div class="card shadow-lg border-0 rounded-lg mt-2" id="box-login">
          <div class="card-header text-center text-white" [ngClass]="bgapp">
            <img src="../../../../assets/img/login/{{logo}}" class="img-login" id="img-login" />
            <p class="mb-0 mt-2">IBERO WEB APPSX</p>
            <p class="mb-0">{{ NombreCompleto }}
              <button class="btn icon-btn text-right ml-1" ngbTooltip="Salir" (click)="logout()">
                <la-icon [icon]="['las','sign-out-alt']" size="1x" class="text-white"></la-icon>
              </button>
            </p>
          </div>
          <div class="card-body">

            <div class="row align-items-center mt-2 bg-white text-ibero">
              <div class="col-md-6 text-center mt-3" *ngFor="let item of AppsLista">
                <button [class.selected]="selectedUser==item.idApp" class="btn icon-btn pt-2 pb-3 pl-1 pr-1"
                  (click)="openApp(item.url);onSelect(item.idApp)" ngbTooltip="">
                  <la-icon [icon]="item.icono" size="2x" class="img-box p-3 item-image bg-white rounded text-ibero">
                  </la-icon>
                </button>
                <p class="item-text text-ibero">{{ item.descripcion }}</p>
              </div>
            </div>

          </div>
          <div class="small mb-1 text-center">
            <div class="small"><label class="text-dark">Versión 2.2.0</label></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Contenedor
<div class="container align-items-center flex-column">

    <--Encabezado
    <div class="text-center pt-3">
      <h4 class="title-section">Ibero Apps</h4>
    </div>
  
    <--Listado
    
      <table class="table table-striped text-center table-fixed">
        <thead class="bg-secondary text-white">
          <tr>
            <th scope="col" class="encabezado orden">idApp</th>
            <th scope="col" class="encabezado orden">Descripción</th>
            <th scope="col" class="encabezado orden">Estatus</th>
          </tr>
        </thead>
        <tbody>
          <tr #item *ngFor="let item of AppsLista; let i = index">
            <td class="w-20">{{item.idApp}}</td>
            <td class="w-10">{{ item.descripcion }}</td>
            <td class="w-10">
              {{ item.activo==1?"Activo":"Inactivo" }}
            </td>
          </tr>
        </tbody>
        <tfoot class="tfoot-ibero text-center bg-secondary text-white">
          <tr>
            <td colspan="9">{{ (AppsLista)?.length }} Registros</td>
          </tr>
        </tfoot>
      </table>
    </div>
    -->